import React from 'react'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';
import { useWindowSize } from "../components/general/window";
import Layout from "../components/layout/layout"
import Head from '../components/general/head'
import { PageContainer, ContentWrapper, DeepBackground, HeadingWrapper, Title, Line, SubTitle, SolidBackground, 
         TextureBackground, Container, MediaContainer, Thumbnail, InfoBox, BigNumber, Top, Bottom, MediaText, MediaTitle, Authors, Description, BumperOffset, Bumper } from '../components/general/general'
import { HelpButton } from "../components/general/helpButton.js"
import Video from "../components/general/video"

import "@fontsource/raleway/900.css"
import "@fontsource/raleway/800.css"
import "@fontsource/raleway/700.css"
import "@fontsource/raleway/600.css"
import "@fontsource/raleway/500.css"
import "@fontsource/raleway/400.css"
import "@fontsource/work-sans/700.css"

const EntriesTemplate = (props) => {

    let window = useWindowSize()
    const width  = window[0]

    return (
            <Layout>
            <Head title={`${props.data.contentfulCharacter.characterNamePossessive} Insights`}/>
            <PageContainer>
                <ContentWrapper>
                <ParallaxProvider>
                    <Parallax
                        y={[-50, 50]}
                        slowerScrollRate
                        disabled={width <= 1024 }
                    >
                        <DeepBackground />
                    </Parallax>
                    
                    <Parallax
                        y={[100, 0]}
                        fasterScrollRate
                        disabled={width <= 1024 }
                    >
                    <HeadingWrapper>
                        <Title color={props.data.contentfulCharacter.characterColors.highlight}>{props.data.contentfulCharacter.characterName}</Title>
                        <Line>
                            <StaticImage
                                src="../images/lines/two-lines.png"
                                alt="Two Curved Light Weight Line"
                                layout="constrained"
                                // css={`
                                //     width: 65%;
                                //     opacity: .8;
                                // `}
                            />
                        </Line>
                        <SubTitle>Therapist Insights</SubTitle>
                    </HeadingWrapper> 
                    </Parallax>
                    <Parallax disabled={width <= 1024 }>
                        <SolidBackground>
                            <TextureBackground>
                                <Bumper>
                                    <HelpButton />
                                </Bumper>
                                <BumperOffset>
                                    <Container>
                                        {props.data.contentfulCharacter.characterInsights.insights.map((insight) => {
                                            return (
                                                <MediaContainer background={props.data.contentfulCharacter.characterColors.primary}>
                                                    {/* <Thumbnail
                                                        image={insight.insightThumbnail.gatsbyImageData}
                                                        alt={insight.insightThumbnail.description}
                                                    /> */}
                                                    <Video 
                                                        videoSrcURL={insight.videoSrcUrl.videoSrcUrl}
                                                        videoTitle={insight.videoTitle}
                                                    />
                                                    <script src="https://player.vimeo.com/api/player.js"></script>
                                                    <InfoBox color={props.data.contentfulCharacter.characterColors.highlight}>
                                                        <BigNumber color={props.data.contentfulCharacter.characterColors.highlight}>
                                                            <Top>
                                                                INSIGHT
                                                            </Top>
                                                            <Bottom>
                                                                #{insight.insightNumber}
                                                            </Bottom>
                                                        </BigNumber>
                                                        <MediaText>
                                                            <MediaTitle>
                                                                {insight.insightTitle}
                                                            </MediaTitle>
                                                            <Authors>
                                                                {insight.insightAuthors}
                                                            </Authors>
                                                            <Description>
                                                                {insight.insightDescription}
                                                            </Description>
                                                        </MediaText>
                                                    </InfoBox>
                                                </MediaContainer>
                                            )
                                        })}
                                    </Container>
                                </BumperOffset>
                            </TextureBackground>
                        </SolidBackground>
                    </Parallax>
                </ParallaxProvider>
                </ContentWrapper>
            </PageContainer>
        </Layout>
    )
}

export const query = graphql`
    query($slug: String!) {
        contentfulCharacter(slug: {eq: $slug}) {
            characterName
            characterNamePossessive
            characterColors {
                primary
                highlight
            }
            characterInsights {
                insights {
                    insightThumbnail {
                        gatsbyImageData (
                            layout: FULL_WIDTH
                            placeholder: BLURRED
                            formats: [AUTO, WEBP]
                        )
                        description
                    }
                    insightNumber
                    insightNumberText
                    insightTitle
                    insightAuthors
                    insightDescription
                    videoTitle
                    videoSrcUrl {
                        videoSrcUrl
                    }
                }
            }
        }
    }
`

export default EntriesTemplate